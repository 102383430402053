<template>
<v-row>
   <v-data-table
    :headers="headers"
    :items="admins"
    item-key="name"
    mobile-breakpoint="900"
    class="elevation-1"
    :footer-props="{
      itemsPerPageText: 'عدد الأسطر في الصفحة:',
    }"
  >
    <template v-slot:top>
      <div class="d-flex justify-end align-center">
        <slot></slot>
      </div>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-row align="center" justify="center" class="pa-4">
        <slot name="action" :data="item"></slot>
      </v-row>
    </template>
  </v-data-table>
</v-row>
 
</template>

<script>

export default {
  name: "BactTable",
  props: {
    headers: Array,
    admins: Array,
    totalCount: String,
    loadingTable: Boolean,
    loadingTableText: String,
  },
};
</script>
