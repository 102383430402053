<template>
  <v-container id="bills">
    <notifications
      group="public"
      position="bottom center"
      :style="{ marginBottom: '2px' }"
    />
    <handel-admin
      v-if="isEdit || addAdmin"
      v-bind="{
        data: editData,
        isEdit: isEdit
      }"
      @handleClose="handleClose"
      @handelRequest="handelRequest"
    ></handel-admin>
    <confirm-delete
      v-if="deleteConfirm >= 0"
      v-bind="{ id: deleteConfirm, isOpen: true }"
      @handleClose="handleClose"
      @handelRequest="handelDelete"
    ></confirm-delete>

    <!-- table -->
    <v-row class="d-flex flex-column text-center">
      <v-col cols="12" class="mb-4">
        <h1 class="display-3 font-weight-bold mb-3 text-grey">
          {{ !isEditors ? this.$t("admins.title") : 'اللجنة الأكاديمية' }}
        </h1>
      </v-col>
      <v-col cols="12">
        <bact-table
          v-bind="{
            headers: headers,
            admins: newAdmins,
          }"
        >
          <download-excel 
            :data="newAdmins"
            :name="isEditors ? 'Editors' : 'Admins'">
            <v-btn class="white--text ma-2"  :color="this.YColor">
              <v-icon>{{this.downloadIcon}}</v-icon>
              {{ $t('table.export') }} 
            </v-btn>
          </download-excel>
          <v-btn
            class="ml-3 mb-3 mb-md-0 text-lg-h6"
            :color="primaryColor"
            dark
            @click="editData = {} ;addAdmin = true"
          >
            <v-icon>{{ plusIcon }}</v-icon>
            {{ !isEditors ? this.$t("admins.add") : 'إضافة مدقق' }}
          </v-btn>
          <template v-slot:action="{ data }">
            <v-btn
              text
              icon
              color="secondary lighten-2"
              @click="handelEdit(data)"
            >
              <v-icon color="green">{{ editIcon }}</v-icon>
            </v-btn>
            <v-btn
              text
              icon
              color="secondary lighten-2"
              @click="deleteConfirm = data.id"
            >
              <v-icon color="red">{{ deleteIcon }}</v-icon>
            </v-btn>
          </template>
        </bact-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BactTable from "./Components/AdminsBaseTable";
import HandelAdmin from "./Components/dialogAdmin";
import ConfirmDelete from "./Components/confirmDelete";
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
  name: "Admins",

  components: { BactTable, HandelAdmin, ConfirmDelete },

  data() {
    return {
      headers: [
        { text: this.$t("admins.id"), value: "id", align: "center" },
        {
          text: this.$t("admins.firstName"),
          value: "firstName",
          align: "center",
        },
        {
          text: this.$t("admins.lastName"),
          value: "lastName",
          align: "center",
        },
        {
          text: this.$t("admins.createdAt"),
          value: "createdAt",
          align: "center",
        },
        { text: this.$t("admins.email"), value: "email", align: "center" },
        {
          text: this.$t("admins.identityNumber"),
          value: "identityNumber",
          align: "center",
        },
        { text: this.$t("admins.phone"), value: "phone", align: "center" },
        {
          text: this.$t("admins.birthDate"),
          value: "birthDate",
          align: "center",
        },
        { text: this.$t("admins.country"), value: "country", align: "center" },
        { text: this.$t("admins.action"), value: "actions", align: "center" },
      ],
      addAdmin: false,
      deleteConfirm: -1,
      newAdmins: [],
      editData: {},
      isEdit: false,
    };
  },

  created () {
    this.init();
  },

  methods: {
    ...mapActions("DashBoard/Admins", [
      "getAllAdmins",
      "createNormalSuperAdmin",
      "updateNormalSuperAdmin",
      "deleteNormalSuperAdmin",
    ]),

    init () {
      this.getAllAdmins().then(() => {
      console.log(this.admins)
      this.newAdmins = [];
      let temp = null;
      if (this.$route.name === 'editors') {
        temp = this.admins.filter(admin => admin.role === 'editor')
      } else {
        temp = this.admins.filter(admin => admin.role === 'normal' || admin.role === 'super')
      }
      temp.forEach(admin => {
        const displayAdmin = {...admin};
        displayAdmin.createdAt = moment(displayAdmin.createdAt).format("YYYY-MM-DD");
        this.newAdmins.push(displayAdmin)
        });

      });
    },

    async handelRequest(payload) {
      if (payload.method === 'edit') {
        await this.updateNormalSuperAdmin(payload.data).then(() => {
          this.handleClose();
          this.init();
        });
      }
      else {
        await this.createNormalSuperAdmin(payload.data).then(() => {
          this.handleClose();
          this.init();
        });
      }
    },

    handelDelete (payload) {
      this.deleteNormalSuperAdmin(payload.id).then(() => {
        this.handleClose();
        this.init()
      })
    },

    handleClose() {
      this.isEdit = false;
      this.addAdmin = false;
      this.deleteConfirm = -1;
    },
    handelEdit(data) {
      this.editData = data;
      this.isEdit = true;
    },
  },

  watch: {
    $route: function () {
      this.init();
    }
  },

  computed: {
    ...mapState("DashBoard/Admins", ["admins", "loadingAdmins"]),
    isEditors () {
      return this.$route.name === 'editors' ? true : false;
    },
  },

  filters: {
    moment: function(date) {
      return moment(date).format("YYYY-MM-DD");
    }
  }
};
</script>
