<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <v-card>
      <v-form ref="form" lazy-validation v-on:submit.prevent>
        <v-card-title class="justify-center">
          <span class="headline">{{
            isEdit ? this.$t("admins.edit") :  this.$t("admins.add")
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row class="first_row">
              <v-col cols="12" md="6">
                {{ this.$t("admins.firstName") }}
                <v-text-field :rules="requiredRule" v-model="data.firstName" clearable></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ this.$t("admins.lastName") }}
                <v-text-field :rules="requiredRule" v-model="data.lastName" clearable></v-text-field>
              </v-col>
            </v-row>

            <v-row class="second_row">
              <v-col  v-if="!isEdit">
                {{ this.$t("admins.password") }}
                <v-text-field :rules="requiredRule" v-model="data.password" clearable></v-text-field>
              </v-col>
              <v-col >
                {{ this.$t("admins.phone") }}
                <v-text-field :rules="requiredRule" v-model="data.phone" clearable></v-text-field>
              </v-col>
            </v-row>

            <v-row class="third_row">
              <v-col cols="12" md="6">
                {{ this.$t("admins.email") }}
                <v-text-field
                :rules="requiredRule"
                  type="email"
                  v-model="data.email"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ this.$t("admins.country") }}
                <v-select :rules="requiredRule" :items="items" v-model="data.country"></v-select>
              </v-col>
            </v-row>

            <v-row class="fourth_row">
              <v-col cols="12" md="6">
                {{
                  this.$t("admins.identityNumber")
                }}
                <v-text-field
                :rules="requiredRule"
                  v-model="data.identityNumber"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                {{ this.$t("admins.birthDate") }}
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="birthDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    :rules="requiredRule"
                      v-model="data.birthDate"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.birthDate"
                    no-title
                    scrollable
                    color="#69d1b6"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text @click="menu = false">Cancel</v-btn>
                    <v-btn
                      color="#69d1b6"
                      @click="$refs.menu.save(data.birthDate)"
                      dark
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row class="fifth_row">
              <v-col cols="12" md="6">
                {{ this.$t("admins.avatar") }}
                <v-file-input  v-model="newAvatar"></v-file-input>
              </v-col>
              <v-col v-if="!isEdit" cols="12" md="6">
                {{ this.$t("admins.role") }}
                <v-text-field :rules="requiredRule" readonly v-model="selectedRole"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-row>
            <v-col cols="12" md="6">
              <v-img :src="getImg" max-width="250px" min-height="150">
            </v-img>
            </v-col>
            <v-col cols="12" md="6">
              <base-button
              class="mx-1"
              :options="{ color: this.grayBack50 }"
              text
              @click="close"
              >
                {{ this.$t("form.cancel") }}
              </base-button>
              <base-button @click="save" options> {{ this.$t("form.save") }} </base-button>
            </v-col>
          </v-row>
          
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseButton from "../../../../../core/Base/Buttons/BaseButton";
export default {
  name: "HandelQuestion",
  props: {
    data: Object,
    isEdit: Boolean,
    editanswer: String,
    editquestion: String,
  },
  data() {
    return {
      requiredRule: [(v) => !!v || this.$t('required.field')],
      avatar: [],
      items: ["Syria", "Lebanon", "Turkey", "USA"],
      dialog: true,
      menu: false,
      newAvatar: null
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.$refs.form.resetValidation();
      this.$emit("handleClose");
    },
    save() {
      if (!this.$refs.form.validate()) return;
      this.data.avatar = this.newAvatar;
      if (!this.isEdit) {
        console.log(this.selectedRole)
        this.data.role = this.selectedRole;
        this.$emit("handelRequest", { data: this.data, method: 'add' });
      }
      else {
        this.data.role = this.selectedRole;
        this.$emit("handelRequest", { data: this.data, method: 'edit' });
      }
    },
  },
    computed: {
      selectedRole () {
        return this.$route.name === 'editors' ? 'editor' : 'normal';
      },
    getImg() {
      if (this.newAvatar) {
        return URL.createObjectURL(this.newAvatar)
      } else {
        return `https://bact-back.usol.ca/${this.data.avatar}`;
      }
    },
  },


  components: { BaseButton },
};
</script>


<style scoped>
</style>